'use client';

import Trans from '~/core/ui/Trans';

import AuthProviderButton from '~/core/ui/AuthProviderButton';
import If from '~/core/ui/If';

import AuthErrorMessage from './AuthErrorMessage';
import PageLoadingIndicator from '~/core/ui/PageLoadingIndicator';
import { sanitizeUrl } from '~/lib/utils';
import configuration from '~/configuration';
import useSignInWithProvider from '~/core/hooks/use-sign-in-with-provider';
import { useGetComponentConfig } from '@portal/branding/hooks/useGetComponentConfig';
import { useFeatureFlag } from '@portal/query';
import { signIn as nextAuthSignIn } from 'next-auth/react';

const OAUTH_PROVIDERS = configuration.auth.providers.oAuth;

const OAuthProviders: React.FCC<{
  returnUrl?: string;
  signUp?: boolean;
}> = (props) => {
  const { data: isOktaEnabled } = useFeatureFlag('portal_okta_login');
  const signInWithProviderMutation = useSignInWithProvider();
  const { data: logoConfig, isLoading: isLogoConfigLoading } = useGetComponentConfig<{
    src: string;
    height: number;
    width: number;
  }>('images.load-indicator');

  const loading = signInWithProviderMutation.isMutating;

  if (!configuration.auth.providers.oAuth.length) {
    return null;
  }

  const onSignInWithOkta = () => {
    nextAuthSignIn('okta', { callbackUrl: '/auth/callback' });
  };

  if (!OAUTH_PROVIDERS || !OAUTH_PROVIDERS.length) {
    return null;
  }

  return (
    <>
      <If condition={loading && !isLogoConfigLoading}>
        <PageLoadingIndicator logoProps={logoConfig?.props} />
      </If>

      <div className={'flex w-full flex-1 flex-col space-y-3'}>
        <div className={'flex-col space-y-2'}>
          {OAUTH_PROVIDERS.map((provider) => {
            if (provider === 'okta' && !isOktaEnabled) return;

            return (
              <AuthProviderButton
                key={provider}
                providerId={provider}
                onClick={async () => {
                  if (provider === 'okta') {
                    onSignInWithOkta();
                    return;
                  }

                  const origin = window.location.origin;
                  const signInFromLink = configuration.paths.signInFromLink;

                  const returnUrlParams = props.returnUrl ? `?redirectPath=${sanitizeUrl(props.returnUrl)}` : '';

                  const returnUrl = [signInFromLink, returnUrlParams].join('');
                  const redirectTo = configuration.site.siteUrl
                    ? [configuration.site.siteUrl, returnUrl].join('')
                    : [origin, returnUrl].join('');

                  const credentials = {
                    provider: provider === 'okta' ? 'oidc' : provider,
                    options: {
                      redirectTo,
                      scopes: 'email',
                    },
                  };

                  try {
                    const credential = await signInWithProviderMutation.trigger(credentials);
                    if (!credential) {
                      return Promise.reject();
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Trans
                  i18nKey={props.signUp ? 'auth:signUpWithProvider' : 'auth:signInWithProvider'}
                  values={{
                    provider: getProviderName(provider),
                  }}
                />
              </AuthProviderButton>
            );
          })}
        </div>

        <AuthErrorMessage error={signInWithProviderMutation.error} />
      </div>
    </>
  );
};

function getProviderName(providerId: string) {
  const capitalize = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

  if (providerId.endsWith('.com')) {
    return capitalize(providerId.split('.com')[0]);
  }

  return capitalize(providerId === 'azure' ? 'microsoft' : providerId);
}

export default OAuthProviders;
