import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import normalizeUrl, { Options as NormalizeUrlOptions } from 'normalize-url';
import { findIndex, propEq, update, concat, merge, updateWith, curry, split, compose, trim } from 'lodash/fp';
import { SetWithCustomizer } from 'lodash';
import getLogger from '~/core/logger';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type Options = {
  stripListLeaders?: boolean;
  listUnicodeChar: string | boolean;
  gfm?: boolean;
  useImgAltText?: boolean;
  preserveLinks?: boolean;
};

/**
 * @function removeMarkdown
 *
 * @description
 * Parse the markdown and returns a string
 *
 * @param markdown - The markdown string to parse
 * @param options - The options for the function
 *
 * @returns The parsed plain text
 */
export const removeMarkdown = (
  markdown: string,
  options: Options = {
    listUnicodeChar: '',
  },
) => {
  options = options || {};
  options.listUnicodeChar = options.hasOwnProperty('listUnicodeChar') ? options.listUnicodeChar : false;
  options.stripListLeaders = options.hasOwnProperty('stripListLeaders') ? options.stripListLeaders : true;
  options.gfm = options.hasOwnProperty('gfm') ? options.gfm : true;
  options.useImgAltText = options.hasOwnProperty('useImgAltText') ? options.useImgAltText : true;
  options.preserveLinks = options.hasOwnProperty('preserveLinks') ? options.preserveLinks : false;

  let output = markdown || '';

  // Remove horizontal rules (stripListHeaders conflict with this rule, which is why it has been moved to the top)
  output = output.replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*$/gm, '');

  try {
    if (options.stripListLeaders) {
      if (options.listUnicodeChar)
        output = output.replace(/^([\s\t]*)([\*\-\+]|\d+\.)\s+/gm, options.listUnicodeChar + ' $1');
      else output = output.replace(/^([\s\t]*)([\*\-\+]|\d+\.)\s+/gm, '$1');
    }
    if (options.gfm) {
      output = output
        // Header
        .replace(/\n={2,}/g, '\n')
        // Fenced codeblocks
        .replace(/~{3}.*\n/g, '')
        // Strikethrough
        .replace(/~~/g, '')
        // Fenced codeblocks
        .replace(/`{3}.*\n/g, '');
    }
    if (options.preserveLinks) {
      // Remove inline links while preserving the links
      output = output.replace(/\[(.*?)\][\[\(](.*?)[\]\)]/g, '$1 ($2)');
    }
    output = output
      // Remove HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove setext-style headers
      .replace(/^[=\-]{2,}\s*$/g, '')
      // Remove footnotes?
      .replace(/\[\^.+?\](\: .*?$)?/g, '')
      .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
      // Remove images
      .replace(/\!\[(.*?)\][\[\(].*?[\]\)]/g, options.useImgAltText ? '$1' : '')
      // Remove inline links
      .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, '$1')
      // Remove blockquotes
      .replace(/^\s{0,3}>\s?/g, '')
      .replace(/(^|\n)\s{0,3}>\s?/g, '\n\n')
      // Remove reference-style links?
      .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
      // Remove atx-style headers
      .replace(/^(\n)?\s{0,}#{1,6}\s+| {0,}(\n)?\s{0,}#{0,} {0,}(\n)?\s{0,}$/gm, '$1$2$3')
      // Remove emphasis (repeat the line to remove double emphasis)
      .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
      .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
      // Remove code blocks
      .replace(/(`{3,})(.*?)\1/gm, '$2')
      // Remove inline code
      .replace(/`(.+?)`/g, '$1')
      // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
      .replace(/\n{2,}/g, '\n\n');
  } catch (e) {
    getLogger().error(e);

    return markdown;
  }
  return output;
};

export default function compareUrls(firstUrl: string, secondUrl: string): boolean {
  if (firstUrl === secondUrl) {
    return true;
  }

  const options: NormalizeUrlOptions = {
    defaultProtocol: 'https',
    removeTrailingSlash: true,
  };

  return normalizeUrl(firstUrl, options) === normalizeUrl(secondUrl, options);
}

export const findBy = curry(function findBy<T extends Record<PropertyKey, unknown>, K extends keyof T>(
  property: K,
  value: T[K],
  data: T[],
) {
  return findIndex(propEq(property, value), data);
});

export const upsertWith = curry(function <T>(
  predicate: (item: T) => boolean,
  target: T,
  customizer: SetWithCustomizer<T[]>,
  data: T[],
) {
  const copy = structuredClone(data);
  const index = findIndex(predicate, copy);

  // @ts-ignore
  const value = customizer(copy[index], target);

  if (index < 0) {
    return concat(copy, value);
  }
  return updateWith(customizer, `[${index}]`, predicate, copy);
});

interface CurriedUpsertBy {
  (property: PropertyKey): <T extends object>(incoming: T, data: T[]) => T[];
  <T extends object, K extends keyof T>(property: K, incoming: T): (data: T[]) => T[];
  <T extends object, K extends keyof T>(property: K, incoming: T, data: T[]): T[];
}

export const upsertBy = curry(function upsertBy<T extends Record<PropertyKey, unknown>, K extends keyof T, U extends T>(
  property: K,
  incoming: U,
  data: T[],
) {
  const index = findIndex((datum) => datum[property] === incoming[property], data);
  return index === -1
    ? concat(data, [incoming])
    : updateWith((left, right) => merge(right, left), findBy(property, incoming), merge(incoming), data);
}) as unknown as CurriedUpsertBy;

type PartitionFn<T> = (item: T) => boolean;

/**
 * Partitions into n categories
 * @param args
 */
export function partitionWith<T, A extends PartitionFn<T>[]>(data: T[], ...partitions: A) {
  if (partitions.length < 1) {
    throw new Error('At least one partition function is required');
  }
  return data.reduce(
    (result, item) => {
      for (const [index, partition] of partitions.entries()) {
        if (partition(item)) {
          result[index].push(item);
          return result;
        }
      }
      result[partitions.length - 1].push(item);
      return result;
    },
    Array.from({ length: partitions.length + 1 }, () => [] as T[]),
  );
}

export const propSatisfies = curry(function propSatisfies<T extends object, K extends keyof T>(
  prop: K,
  condition: (item: T[K]) => boolean,
  data: T,
) {
  return condition(data[prop]);
});

export const sanitizeUrl = (url: string) => {
  return url.replace(/[^a-zA-Z0-9-_\/]/g, '');
};
